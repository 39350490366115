<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card" v-if="infoshow">
          <div>
            你还没有添加小程序初始信息
            <el-button type="primary" @click="dialogFormVisible = true"
              >立即添加</el-button
            >
          </div>
        </el-card>
        <el-card class="box-card" v-if="!infoshow">
          <el-descriptions class="margin-top" title="小程序信息" :column="1" border>
            <template slot="extra">
              <el-button type="primary" size="small"  @click="ceshi()">修改信息</el-button>
            </template>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-picture-outline"></i>
                logo
              </template>
              <img :src="appletinfo.logourl" class="logo" alt="">
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-bangzhu"></i>
                名称
              </template>
              {{appletinfo.name}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-document"></i>
                版本
              </template>
              {{appletinfo.version}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-c-scale-to-original"></i>
                云图片url
              </template>
              {{appletinfo.cdnurl}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-partly-cloudy"></i>
                云存储平台
              </template>
              {{appletinfo.filesystem}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-tickets"></i>
                用户协议
              </template>
              <el-tag size="small" @click="vueeditor('user_protocol',appletinfo.user_protocol,appletinfo._id)">修改</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-notebook-2"></i>
                隐私协议
              </template>
              <el-tag size="small" @click="vueeditor('privacy_protocol',appletinfo.privacy_protocol,appletinfo._id)">修改</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-discount"></i>
                关于我们
              </template>
              <el-tag size="small" @click="vueeditor('about_us',appletinfo.about_us,appletinfo._id)">修改</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-office-building"></i>
                公司信息
              </template>
              {{appletinfo.copyright}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-loading"></i>
                版权信息
              </template>
              {{appletinfo.copytime}}
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-col>
      <el-dialog :title="appletinfo._id ? '更新' : '添加'" :visible.sync="dialogFormVisible">
              <el-form :model="appletinfo">
                <el-form-item label="logo" :label-width="formLabelWidth">
                  <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="appletinfo.logourl" :src="appletinfo.logourl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label="名称" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.name"
                    autocomplete="off"
                    placeholder="小程序名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="版本号" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.version"
                    autocomplete="off"
                    placeholder="小程序的版本号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="云存储域名" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.cdnurl"
                    autocomplete="off"
                    placeholder="图片文件等cdn域名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="存储平台" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.filesystem"
                    autocomplete="off"
                    placeholder="请输入左侧菜单路径：注意唯一"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司名称" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.copyright"
                    autocomplete="off"
                    placeholder="公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="版权信息" :label-width="formLabelWidth">
                  <el-input
                    v-model="appletinfo.copytime"
                    autocomplete="off"
                    placeholder="版权信息"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button v-if="appletinfo._id" type="primary" @click="editapplet(appletinfo)">修改保存</el-button>
                <el-button v-if="!appletinfo._id" type="primary" @click="createapplet(appletinfo)">立即保存</el-button>
              </div>
            </el-dialog>
            <el-dialog
              :title="editortitle"
              :visible.sync="dialogVisible"
              width="30%"
              :closeOnClickModal="false"
              >
              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="content"
              >
              </vue-editor>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveeditor(editname,appletinfo._id)">确 定</el-button>
              </span>
            </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { 
    VueEditor
  },
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormedit: false,
      dialogVisible: false,
      dialogFormVisible: false,
      dialogappletmenu: false,
      imageUrl: "",
      content: "",
      editortitle: "",
      editname:"",
      apiurl: this.$api,
      infoshow: false,
      menushow:true,
      editform: {},
      edit: {},
      appletinfo: {
        _id:"",
        name: "",
        logourl: "",
        version: "",
        cdnurl: "",
        filesystem: "",
        user_protocol: "",
        privacy_protocol: "",
        about_us: "",
        copyright: "",
        copytime: "",
      },
    };
  },
  created() {
    this.getapplet()
  },
  methods: {
    ceshi(){
      this.dialogFormVisible = true;
    },
    vueeditor(title,name,id){
      this.editname = title
      if(title === 'user_protocol'){
        this.editortitle = "用户协议"
      }else if(title === 'privacy_protocol'){
        this.editortitle = "隐私协议"
      }else{
        this.editortitle = "关于我们"
      }
      this.dialogVisible = true
      this.content = name
    },
    saveeditor(title,id){
      const data = {
        _id:id,
        title:title,
        content:this.content
      }
      this.$api.applet.saveeditor(data).then(res=>{
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.dialogVisible = false;
        this.getapplet()
      }).catch(err=>{
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
        this.dialogVisible = false;
      })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append("contentimg", file);
      this.$api.upload
        .contentimg(formData)
        .then(result => {
          const url = result.data.data; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getapplet(){
      this.$api.applet.list().then(res=>{
        if(res.data.data !== null){
          this.appletinfo = res.data.data.info
        }else{
          this.infoshow = true 
        }
      })
    },
    editapplet(data){
      this.$api.applet.editapplet(data).then(res=>{
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getapplet()
      }).catch(err=>{
        this.$message({
          message: res.data.msg,
          type: "warning",
        });
        this.dialogFormVisible = false;
      })
    },
    uploadFile(param) {
      const formData = new FormData();
      formData.append("logo", param.file);
      console.log(param.file);
      this.$api.upload
        .logo(formData)
        .then((res) => {
          this.appletinfo.logourl = res.data.data;
          console.log(res.data.data);
        })
        .catch((res) => {
          this.$message.error("上传失败，请稍后重试");
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    createapplet(data) {
      //console.log(this.form.parent)
      this.$api.applet
        .createapplet(data)
        .then((res) => {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.dialogFormVisible = false;
          this.appletinfo = res.data.data.info;
          this.getapplet()
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.el-row {
  margin-bottom: 20px;
}
.el-row :last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
</style>
